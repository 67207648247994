import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-aurora-illinois.png'
import image0 from "../../images/cities/scale-model-of-the-basement-attractions-in-aurora-illinois.png"
import image1 from "../../images/cities/scale-model-of-mcdowell-grove-in-aurora-illinois.png"
import image2 from "../../images/cities/scale-model-of-phillips-park-zoo-in-aurora-illinois.png"
import image3 from "../../images/cities/scale-model-of-riveredge-park-in-aurora-illinois.png"
import image4 from "../../images/cities/scale-model-of-blackberry-farm-in-aurora-illinois.png"
import image5 from "../../images/cities/scale-model-of-aurora-regional-fire-museum-in-aurora-illinois.png"
import image6 from "../../images/cities/scale-model-of-aurora-downtown-in-aurora-illinois.png"
import image7 from "../../images/cities/scale-model-of-aurora-area-convention-&-visitors-bureau-in-aurora-illinois.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Aurora'
            state='Illinois'
            image={image}
            lat='41.7605849'
            lon='-88.32007150000004'
            attractions={ [{"name": "The Basement Attractions", "vicinity": "50 W New York St, Aurora", "types": ["point_of_interest", "establishment"], "lat": 41.7591283, "lng": -88.31589229999997}, {"name": "McDowell Grove", "vicinity": "Naperville", "types": ["park", "point_of_interest", "establishment"], "lat": 41.7965051, "lng": -88.18823789999999}, {"name": "Phillips Park Zoo", "vicinity": "1000 Ray Moses Dr, Aurora", "types": ["zoo", "point_of_interest", "establishment"], "lat": 41.7364522, "lng": -88.29486600000001}, {"name": "RiverEdge Park", "vicinity": "360 North Broadway Street, Aurora", "types": ["point_of_interest", "establishment"], "lat": 41.7638044, "lng": -88.3087059}, {"name": "Blackberry Farm", "vicinity": "100 S Barnes Rd, Aurora", "types": ["point_of_interest", "establishment"], "lat": 41.764884, "lng": -88.39101099999999}, {"name": "Aurora Regional Fire Museum", "vicinity": "53 N Broadway, Aurora", "types": ["museum", "point_of_interest", "establishment"], "lat": 41.75820299999999, "lng": -88.311442}, {"name": "Aurora Downtown", "vicinity": "43 W Galena Blvd, Aurora", "types": ["point_of_interest", "establishment"], "lat": 41.7589909, "lng": -88.31585369999999}, {"name": "Aurora Area Convention & Visitors Bureau", "vicinity": "43 W Galena Blvd, Aurora", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 41.758747, "lng": -88.31590699999998}] }
            attractionImages={ {"The Basement Attractions":image0,"McDowell Grove":image1,"Phillips Park Zoo":image2,"RiverEdge Park":image3,"Blackberry Farm":image4,"Aurora Regional Fire Museum":image5,"Aurora Downtown":image6,"Aurora Area Convention & Visitors Bureau":image7,} }
       />);
  }
}